import React from 'react'
import thankupage from'../Assets/Images/thankupage.png'
import { Link } from 'react-router-dom'

const ThankYou = () => {
  return (
    <>
                <div className="innerslide">
                <h3>Thank You</h3>
                <ul className="braedcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Thank You</li>
                </ul>
            </div>
            <div className="innersec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Link to="/"><img src={thankupage} className="img-fluid mx-auto d-block" alt="Lance Fort International School" /></Link>
                            </div>
                        </div>
                    </div>
            </div>
    </>
  )
}

export default ThankYou
