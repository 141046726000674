import React from 'react'
import MidSecInfoprop from'../Components/MidSecInfoprop'
import aboutusicn  from '../Assets/Images/aboutusicn.png'
import Managementicn from '../Assets/Images/Managementicn.png'
import facilitiesicn from '../Assets/Images/facilitiesicn.png'
import sportsicn from '../Assets/Images/sportsicn.png'
import Enrichmentprogramsicn from '../Assets/Images/Enrichmentprogramsicn.png'
const MidSecInfo = () => {
  return (
    <>
       <MidSecInfoprop title="About Us" image={aboutusicn}/>
       <MidSecInfoprop title="Management" image={Managementicn}/>
       <MidSecInfoprop title="facilities" image={facilitiesicn}/>
       <MidSecInfoprop title="sports" image={sportsicn}/>
       <MidSecInfoprop title="Enrichment programs" image={Enrichmentprogramsicn}/>
    </>
  )
}

export default MidSecInfo
