import React from 'react'
import sport1 from'../Assets/Images/sport1.jpg'
import sport2 from'../Assets/Images/sport2.jpg'
import sport3 from'../Assets/Images/sport3.jpg'
import { SlideshowLightbox } from 'lightbox.js-react'
import { Link } from 'react-router-dom'
const Sports = () => {
  return (
    <>
    <div className="innerslide">
    <h3>Sports</h3>
    <ul className="braedcrumb">
        <li><Link to="/">Home</Link></li>
        <li>Infrastructure</li>
        <li>Sports</li>
    </ul>
        </div>
        <div className="innersec">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                        <div className="laboratoriesimg">
                        <SlideshowLightbox><img src={sport1} className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                        </div>
                </div>
                <div className="col-lg-4 col-md-6">
                        <div className="laboratoriesimg">
                        <SlideshowLightbox><img src={sport2} className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                        </div>
                </div>
                <div className="col-lg-4 col-md-6">
                        <div className="laboratoriesimg">
                        <SlideshowLightbox><img src={sport3} className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                        </div>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default Sports
