import React from 'react'
import SkilingInfoprops from'../Components/SkilingInfoprops'
import skilling1 from '../Assets/Images/skilling1.png'
import skilling2 from '../Assets/Images/skilling2.png'
import skilling3 from '../Assets/Images/skilling3.png'
import skilling4 from '../Assets/Images/skilling4.png'
import skilling5 from '../Assets/Images/skilling5.png'
import skilling6 from '../Assets/Images/skilling6.png'
const SkilingInfo = () => {
  return (
    <>
       <SkilingInfoprops title="Fine Arts" image={skilling1}/>
       <SkilingInfoprops title="Athletics" image={skilling2}/>
       <SkilingInfoprops title="Sports" image={skilling3}/>
       <SkilingInfoprops title="Resource Room" image={skilling4}/>
       <SkilingInfoprops title="Dining Hall" image={skilling5}/>
       <SkilingInfoprops title="Summer Camp Programs" image={skilling6}/>
    </>
  )
}

export default SkilingInfo
