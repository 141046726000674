import React, { useState } from "react";
import { getHeader } from "../config"
import { form } from "../Service/Api";
import { Link } from 'react-router-dom'

const ContactUs = () => {


   const [formData, setFormData] = useState({
     name: "",
     email: "",
     phone: "",
     message: "",   
   });

   const handleChange = (e) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value
      });
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
   
      try {
         const { header } = getHeader(); // Retrieve headers from getHeader function
         const response = await form(formData, header); // Pass the headers to the form function
         console.log('Form submitted successfully', response);
         document.querySelector(".primary-alert").innerHTML = "<p>Your Form Submitted Successfully.</p>";
         document.querySelectorAll(".input, textarea").forEach(input => {
            input.value = "";
        });
      } catch (error) {
         console.error('Error submitting form:', error); 
      }
   };

   return (
      <>
         <div className="innerslide">
            <h3>Contact Us</h3>
            <ul className="braedcrumb">
               <li><Link to="/">Home</Link></li>
               <li>Contact Us</li>
            </ul>
         </div>
         <div className="innersec">
            <div className="container">
               <div className="formbox">
                  <div className="formboxleft">
                     <h5>Get In Touch</h5>
                     <div className="addressdesc">
                        <div className="addressimg"><i className="bi bi-geo-alt-fill"></i></div>
                        <Link to="#">Lance Fort International School, Opposite Sunil Colony, Burari, Delhi-110084</Link>
                     </div>
                     <div className="addressdesc">
                        <div className="addressimg"><i className="bi bi-envelope-fill"></i></div>
                        <Link to="mailto:info@lancefortinternationalschool.com">info@lancefortinternationalschool.com</Link>
                     </div>
                     <div className="addressdesc">
                        <div className="addressimg"><i className="bi bi-telephone-fill"></i></div>
                        <Link to="tel:(+91)- 7303799381-83">(+91)- 7303799381-83</Link>
                     </div>
                  </div>                  
                  
                  <div className="formboxright">
                     
               <div className="primary-alert"></div>
                     <form onSubmit={handleSubmit}>
                        <div className="formdiv">
                           <div className="form-group">
                              <label>Name<span>*</span></label>
                              <input type="text" onChange={handleChange} value={formData.name} name="name"  className="form-control input" required />
                           </div>
                           <div className="form-group">
                              <label>Email<span>*</span></label>
                              <input type="email" onChange={handleChange} value={formData.email} name="email" className="form-control input" required />
                           </div>
                           <div className="form-group">
                              <label>Phone No.<span>*</span></label>
                              <input type="text" onChange={handleChange} value={formData.phone} name="phone" className="form-control input" required />
                           </div>
                           <div className="form-group">
                              <label >Your Message<span>*</span></label>
                              <textarea className="form-control" onChange={handleChange} value={formData.message} name="message" required></textarea>
                           </div>
                        </div>
                        <input type="submit" value="Submit" />
                        
                     </form>
                  </div>
                  
               </div>
            </div>
            <div className="container-fluid">
               <div className="row">
                  <div className="col-lg-12">
                     <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d447678.81958769436!2d77.196022!3d28.764617000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cffe590583ed1%3A0xe6a348b7dda31f4!2sLance%20Fort%20International%20School!5e0!3m2!1sen!2sus!4v1704274126040!5m2!1sen!2sus" width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default ContactUs