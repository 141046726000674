import React from 'react'
import computerlab1 from'../Assets/Images/computer-lab1.jpg'
import computerlab2 from'../Assets/Images/computer-lab2.jpg'
import computerlab3 from'../Assets/Images/computer-lab3.jpg'
import computerlab4 from'../Assets/Images/computer-lab4.jpg'
import computerlab5 from'../Assets/Images/computer-lab5.jpg'
import { SlideshowLightbox } from 'lightbox.js-react'
import { Link } from 'react-router-dom'
const ComputerLab = () => {
  return (
     <>
     <div className="innerslide">
     <h3>Computer Lab</h3>
     <ul className="braedcrumb">
         <li><Link to="/">Home</Link></li>
         <li>Infrastructure</li>
         <li>Computer Lab</li>
     </ul>
</div>
<div className="innersec">
 <div className="container">
     <div className="row">
          <div className="col-lg-4 col-md-6">
                 <div className="laboratoriesimg">
                 <SlideshowLightbox><img src={computerlab1} className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                    </div>
          </div>
          <div className="col-lg-4 col-md-6">
                 <div className="laboratoriesimg">
                 <SlideshowLightbox><img src={computerlab2} className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                    </div>
          </div>
          <div className="col-lg-4 col-md-6">
                 <div className="laboratoriesimg">
                 <SlideshowLightbox><img src={computerlab3} className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                    </div>
          </div>
          <div className="col-lg-4 col-md-6">
                 <div className="laboratoriesimg">
                 <SlideshowLightbox><img src={computerlab4} className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                    </div>
          </div>
          <div className="col-lg-4 col-md-6">
                 <div className="laboratoriesimg">
                 <SlideshowLightbox><img src={computerlab5} className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                    </div>
          </div>
     </div>
 </div>
</div>
     </>
  )
}

export default ComputerLab
